

































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import { InventoryItem } from "@/interfaces/entities/InventoryItem";
import TableList from "@/components/partials/TableList.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import PackageCard from "@/components/partials/PackageCard.vue";
import { Product } from "@/interfaces/entities/Product";
import { JoinerBonusInfo } from "@/interfaces/entities/JoinerBonusInfo";
import { emojiFlag } from "@/util/emojiFlag";
import { Filter } from "@/interfaces/entities/Filter";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import { getFilteredProducts } from "@/util/filterUtils";
import { tools } from "@/util/tools";

@Component({
    components: {
        FilterPanel,
        TDSModal,
        Inputfield,
        TableList,
        DefaultLayout,
        TDSButton,
        PackageCard,
        TDSSpinner
    }
})
export default class AddPackages extends Vue {
    private cartBoxIsOpen: boolean = false;
    private currentInventoryItems: Product[] = [];
    private inventoryItems: Product[] = [];
    // private bulkDiscount: BulkDiscount | null = null;
    // private bulkDiscountInfo: BulkDiscountInfo[] = [];
    private loadingInventoryPrice: boolean = false;
    private inventoryListenerName: string = "products-list-listener";
    private selectedAmounts: { [productId: string]: number } = {};
    private stepsCardVisible: boolean = false;
    private maxInventoryItemsScrollMobile: number = 160;
    private showPackageModal: boolean = false;
    private showInventoryUpdatedModal: boolean = false;
    private modalPackage?: Product;
    private tableColumns: object = {
        coverage: { label: this.$t("tableHeader.coverage") as string, sortable: true, sortBy: (product: Product) => product.countrySet },
        sizeValue: {
            label: this.$t("tableHeader.volume") as string,
            sortable: true,
            width: "80px",
            align: "right",
            sortBy: tools.sortBySizeValue
        },
        validitySize: {
            label: this.$t("tableHeader.validity") as string,
            sortable: true,
            width: "100px",
            align: "right"
        },
        price: {
            label: this.$t("tableHeader.price") as string,
            sortable: true,
            width: "120px",
            align: "right",
            sortBy: (product: Product) => (product.prices ? product.prices[0].priceValue : 0)
        },
        detail: { label: "", sortable: false, width: "32px", align: "center" },
        add: { sortable: false, width: "140px", align: "center" }
    };
    private waitForHtmlElementsTimeout?: ReturnType<typeof setTimeout> = undefined;
    private showWelcomeBonus: boolean = false;
    private joinerBonusInfo: JoinerBonusInfo | null = null;
    private filters: { [key: string]: Filter } = {
        coverage: {
            val: "countrySet",
            key: "countrySet",
            label: (item: any) => this.$options.filters?.countryName(item.countrySet),
            placeholder: "All Coverage",
            icon: "mobile-data-grey24"
        },
        validity: {
            val: "validitySize",
            key: "validitySize",
            label: (item: any) => item.validitySize + " " + item.validityUnit,
            placeholder: "All Validities"
        },
        volumes: {
            compositeFields: ["sizeValue", "sizeUnit"],
            label: (item: any) => item.sizeValue + " " + item.sizeUnit,
            placeholder: "All Volumes",
            icon: "pricing-esim"
        }
    };
    private filteredItems: Product[] = [];
    private isLoading: boolean = true;

    get availableProducts() {
        return getFilteredProducts(this.$store.state.availableProducts);
    }

    get isMobile() {
        return this.$store.state.isMobile;
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("addPackages.pageTitle");
        this.fetchInventory();
        this.$store.dispatch("FETCH_AVAILABLE_PRODUCTS")
            .finally(() => {
                this.isLoading = false;
            });
        this.$store.dispatch("FETCH_JOINER_BONUS_INFO").then((joinerBonusInfo: JoinerBonusInfo) => {
            this.joinerBonusInfo = joinerBonusInfo;
            this.showWelcomeBonus = joinerBonusInfo.showToUser;
        });
    }

    async fetchInventory() {
        this.inventoryItems = await this.$store.dispatch("FETCH_INVENTORY");
        if (this.currentInventoryItems.length === 0) this.currentInventoryItems = JSON.parse(JSON.stringify(this.inventoryItems));
    }

    @Watch("availableProducts")
    onAvailableProductsChange(products: Array<Product>) {
        products.forEach((p: Product) => {
            if (!this.selectedAmounts[p.id]) {
                this.$set(this.selectedAmounts, p.id, 0);
            }
        });
    }

    @Watch("cartBoxIsOpen")
    cartBoxIsOpenChanged() {
        this.setCustomStyles();
    }

    @Watch("showWelcomeBonus")
    showWelcomeBonusChanged() {
        this.updateInventoryBoxOpenState();
    }

    @Watch("isMobile")
    isMobileChanged() {
        if (this.showWelcomeBonus) this.cartBoxIsOpen = true;
        else this.cartBoxIsOpen = false;
    }

    @Watch("inventoryItems")
    itemsChanged() {
        console.log("[ProductsList] Inventory items changed: ", this.inventoryItems);
        this.updateInventoryBoxOpenState();
    }

    private getEmojiFlag(countryCode: string): string {
        return emojiFlag(countryCode, "🌏");
    }

    public updateInventoryBoxOpenState() {
        if (this.showWelcomeBonus) this.cartBoxIsOpen = true;
        if (this.cartBoxIsOpen && (!this.inventoryItems || this.inventoryItems.length === 0)) {
            this.cartBoxIsOpen = false;
        } else if (!this.cartBoxIsOpen && this.inventoryItems && this.inventoryItems.length > 0) {
            this.cartBoxIsOpen = true;
        }
        this.setCustomStyles();
    }

    public isInInventory(item: Product): boolean {
        return Boolean(this.inventoryItems.find((inventoryItem: Product) => inventoryItem.productId === item.id));
    }

    public addToInventoryFromModal(item: Product): void {
        this.addToInventory(item);
        this.closeDetailModal();
    }

    public addToInventory(item: Product): void {
        if (this.isInInventory(item)) return;
        item.productId = item.id;
        this.inventoryItems.push(item);
    }

    public toggleItemInInventory(item: Product): void {
        if (this.isInInventory(item)) this.removeFromInventory(item.id);
        else this.addToInventory(item);
    }

    public removeFromInventory(productId: string): void {
        console.log(productId);
        const itemToRemove = this.inventoryItems.findIndex((item: Product) => item.productId === productId);
        console.log(itemToRemove);

        if (itemToRemove > -1) this.inventoryItems.splice(itemToRemove, 1);
    }

    public async updateInventory() {
        const inventoryIds = this.inventoryItems.map((item: Product) => item.productId);
        const currentInventoryIds = this.currentInventoryItems.map((item: Product) => item.productId);
        const response = await this.$store.dispatch("UPDATE_INVENTORY", {
            addedPacks: inventoryIds.filter((item: string | undefined) => {
                return !currentInventoryIds.includes(item);
            }),
            removedPacks: currentInventoryIds.filter((item: string | undefined) => {
                return !inventoryIds.includes(item);
            })
        });
        await this.$router.push("/your-store/inventory");
    }

    public setCustomStyles() {
        console.log("[ProductsList] Set custom styles");
        const productCard = this.$refs.productCard as HTMLElement;
        const inventoryItems = this.$refs.inventoryItems as HTMLElement;
        if (this.waitForHtmlElementsTimeout) clearTimeout(this.waitForHtmlElementsTimeout);
        if (!inventoryItems || !productCard) {
            this.waitForHtmlElementsTimeout = setTimeout(() => {
                this.setCustomStyles();
            }, 500);
            return;
        }
        if (this.cartBoxIsOpen) {
            let extraHeight = 0;
            if (this.showWelcomeBonus) extraHeight += 80;
            inventoryItems.style.height = this.inventoryItems.length * 64 + extraHeight + "px";
            if (inventoryItems.scrollHeight < this.maxInventoryItemsScrollMobile) {
                productCard.style.marginBottom = inventoryItems.scrollHeight + 140 + "px";
            } else {
                productCard.style.marginBottom = this.maxInventoryItemsScrollMobile + 140 + "px";
            }
        } else {
            inventoryItems.style.height = "0";
            productCard.style.marginBottom = "140px";
        }
        if (!this.isMobile) productCard.style.marginBottom = "inherit";
    }

    private openDetailModal(item: Product) {
        this.modalPackage = item;
        this.showPackageModal = true;
    }

    private closeDetailModal() {
        this.modalPackage = undefined;
        this.showPackageModal = false;
    }

    private changeSelectedAmount(productId: string, amount: number) {
        if (amount >= 0) {
            this.$set(this.selectedAmounts, productId, amount);
        }
    }

    private toggleInventoryBox() {
        if (this.inventoryItems.length === 0 && !this.showWelcomeBonus) return;
        this.cartBoxIsOpen = !this.cartBoxIsOpen;
    }

    private handleSelectAll(rows: Product[]): void {
        // Create a Set of existing productIds for faster lookup
        const existingIds: Set<string> = new Set(
            this.inventoryItems
                .flatMap((item: Product) => item.productId || [])
        );

        // Check if all items are selected using the Set
        const allSelected: boolean = rows.every((product: Product) => existingIds.has(product.id));

        this.$nextTick(() => {
            if (!allSelected) {
                // Add only items that don't exist
                const newItems: Product[] = rows
                    .filter((product: Product) => !existingIds.has(product.id))
                    .map((product: Product) => ({
                        ...product,
                        productId: product.id
                    }));

                if (newItems.length > 0) {
                    this.inventoryItems = [...this.inventoryItems, ...newItems];
                }
            } else {
                // Remove all selected items
                const idsToRemove: Set<string> = new Set(rows.map((product: Product) => product.id));
                this.inventoryItems = this.inventoryItems.filter(
                    (item: Product) => !idsToRemove.has(item?.productId || "")
                );
            }
        });
    }
}

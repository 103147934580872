



























import {Vue, Component, Watch} from "vue-property-decorator";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSInputField from "@/components/common/TDSInputField.vue";
import CountryPicker from "@/components/partials/CountryPicker.vue";
import {Country} from "@/interfaces/Country";

@Component({
    components: {TDSInputField, TDSButton, CountryPicker}
})
export default class NewCustomer extends Vue {
    private email: string | null = null;
    private emailValid: boolean = true;
    private countryValid: boolean = true;
    private selectedCountry: Country | null = null;
    private isGuest: boolean = this.$route.query.guest === "true";

    @Watch("$route.query")
    queryChanged() {
        this.isGuest = this.$route.query.guest === "true";
        console.log("Is guest is: ", this.isGuest);
    }


    private continueActivation() {
        if (!this.isGuest && !this.emailValid) return;
        if (!this.countryValid) return;
        if (this.isGuest) {
            this.$router.push({path: "/store/guest/packages", query: {countryCode: this.selectedCountry?.code}});
        } else {
            this.$router.push({path: "/store/new/packages", query: {email: this.email, countryCode: this.selectedCountry?.code}});
        }
    }

    selectedCountryChanged(newSelectedCountry: Country) {
        this.selectedCountry = newSelectedCountry;
        this.countryValid = true;
    }

    incorrectCountry() {
        this.countryValid = false;
    }
}

















import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import TDSSelect from "@/components/common/TDSSelect.vue";
import {Filter} from "@/interfaces/entities/Filter";

@Component({
    components: {TDSSelect}
})
export default class FilterPanel extends Vue {
    @Prop({type: Array, required: true}) items!: any;
    @Prop({type: Array, required: true}) filteredItems!: any;
    @Prop({type: Object, required: true}) filters!: { [key: string]: Filter };
    private filterEntries: { [key: string]: Filter[] } = {};
    private activeFilters: Filter[] = [];

    created() {
        this.getFilteredItems();
        this.getAllFilterEntries();
    }

    @Watch("items")
    itemsChanged() {
        this.getFilteredItems();
    }

    @Watch("activeFilters")
    activeFiltersChanged(val: any) {
        this.getFilteredItems();
    }

    private getFilteredItems() {
        if (this.activeFilters.length === 0) {
            this.$emit("update:filteredItems", this.items.filter((item: any) => !item.isHeader));
            return;
        }

        this.$emit("update:filteredItems", this.items.filter((item: any) => {
            return !item.isHeader &&
                this.activeFilters.every((activeFilter: Filter) => {
                    const filter = this.filters[activeFilter.key!];

                    if (filter.compositeFields) {
                        // Handle composite filters
                        const itemCompositeVal = filter.compositeFields
                            .map((field: string) => item[field])
                            .join("-");
                        return itemCompositeVal === activeFilter.val;
                    } else {
                        // Handle regular filters
                        const prop = String(filter.val);
                        return this.getItemProp(item, prop) === activeFilter.val;
                    }
                });
        }));
    }

    private getAllFilterEntries() {
        Object.entries(this.filters).forEach(([filterKey, filter]: [string, Filter]) => {
            this.filterEntries[filterKey] = this.getFilterEntries(filterKey, filter);
        });
    }

    private getFilterEntries(filterKey: string, filter: Filter): Filter[] {
        const unique: Filter[] = [];
        this.items.forEach((item: any) => {
            if (filterKey === "coverage" && item.isHeader) {
                // Only add section headers for the "coverage" filter
                unique.push({ val: undefined, key: item.name, isHeader: true });
            } else if (!item.isHeader) {
                if (filter.compositeFields) {
                    // Handle composite filters
                    const compositeVal = filter.compositeFields
                        .map((field: string) => item[field])
                        .join("-");

                    if (!unique.find((u: Filter) => u.val === compositeVal)) {
                        unique.push({
                            val: compositeVal,
                            key: filter.label!(item)
                        });
                    }
                } else {
                    // Handle regular filters
                    const itemProp = this.getItemProp(item, String(filter.val));
                    if (!unique.find((u: Filter) => u.val === itemProp)) {
                        unique.push({
                            val: itemProp,
                            key: filter.label!(item)
                        });
                    }
                }
            }
        });
        return unique;
    }

    private getItemProp(item: any, prop: string): string | number | undefined {
        if(!item) return undefined;
        const props = prop.split(".");
        if(props.length === 1) return item[prop];
        else return this.getItemProp(item[props[0]], props.slice(1).join("."));
    }

    private updateFilters(key: string, value: string) {
        const entry = this.activeFilters.find((filter: Filter) => filter.key === key);
        if (entry) this.activeFilters.splice(this.activeFilters.indexOf(entry), 1);
        if (value) this.activeFilters.push({key, val: value});
    }
}








import { Component, Vue } from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
// @ts-ignore
import SwaggerUI from "swagger-ui";
import spec from "../../assets/esim-reseller-api.yaml";
import "swagger-ui/dist/swagger-ui.css";

@Component({
    components: { DefaultLayout }
})
export default class APISpecifications extends Vue {
    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("apiSpecs.pageTitle");
    }
    mounted() {
        SwaggerUI({
            spec: spec,
            dom_id: "#swagger"
        });
    }
}






































import {Component, Vue, Watch} from "vue-property-decorator";
import StoreLayout from "../layouts/StoreLayout.vue";
import PackageCard from "@/components/partials/PackageCard.vue";
import {InventoryItem} from "@/interfaces/entities/InventoryItem";
import TDSButton from "@/components/common/TDSButton.vue";
import {tools} from "@/util/tools";
import TDSSpinner from "@/components/common/TDSSpinner.vue";

@Component({
    components: {TDSSpinner, TDSButton, PackageCard, StoreLayout}
})
export default class POSStore extends Vue {
    private isLoading: boolean = false;
    private inventoryItems: InventoryItem[] = [];
    private colors: Array<string> = ["green", "blue", "red", "black", "gray"];

    created() {
        if(!this.$route.params.type || !this.$store.state.validCustomerTypes.includes(this.$route.params.type)) this.$router.replace("/store");
        else if(this.$route.params.type === "existing" && !this.$route.query.customerUid) this.$router.replace("/store/existing");
        this.fetchData();
    }

    @Watch("$route.params")
    paramsChanged(value?: {type: string}) {
        if(!value || !this.$store.state.validCustomerTypes.includes(value)) this.$router.replace("/store");
        else if(value.type === "existing" && !this.$route.query.customerUid) this.$router.replace("/store/existing");

    }

    get isMobile() {
        return this.$store.state.isMobile;
    }

    private async fetchData() {
        this.isLoading = true;
        try {
            this.inventoryItems = (await this.$store.dispatch("FETCH_INVENTORY"))
                .sort((itemA: InventoryItem, itemB: InventoryItem) => {
                    try {
                        const bytesA = tools.convertBytes(itemA.sizeValue, "B", itemA.sizeUnit, 0);
                        const bytesB = tools.convertBytes(itemB.sizeValue, "B", itemB.sizeUnit, 0);
                        return bytesA - bytesB;
                    } catch (e) {
                        console.log("[POSS] Can't convert to bytes: ", itemA, itemB);
                    }
                    return 0;
                })
                .map((item: InventoryItem, index: number) => {
                    item.color = this.colors[index % this.colors.length];
                    return item;
                });
        } catch (e) {
            console.error("[POS Store] Failed fetching inventory items");
        } finally {
            this.isLoading = false;
        }

    }

    private selectPackage(id: string) {
        this.$router.push({path: this.$route.path + "/" + id, query: this.$route.query});
    }
}
